export default class ErrorsBag {
    constructor() {
        this.errors = {};
    }

    /**
     * @param {string} fieldName
     * @returns {string|boolean}
     */
    pop(fieldName) {
        if (!this.has(fieldName)){
            return false;
        }
        const error = this.errors[fieldName];
        delete this.errors[fieldName];
        return error;
    }

    /**
     * @param {string} fieldName
     * @returns {string|boolean}
     */
    get(fieldName) {
        if (!this.has(fieldName)){
            return false;
        }
        return this.errors[fieldName];
    }


    has(fieldName) {
        return typeof this.errors[fieldName] === 'string';
    }

    /**
     * @param {string} fieldName
     * @param {string} error
     */
    set(fieldName, error) {
        this.errors[fieldName] = error;
    }
}