/** 
 * This file contain extensions for jquery-validate plugin
 */
import jQuery from 'jquery-validation';
import '@/jquery-plugins/validate';
import * as utils from '@/utils';

jQuery.fn.serializeObject = function() {
    const arraySerialized = this.serializeArray();
    const outputObject = {};
    arraySerialized.forEach(element => {
        outputObject[element.name] = element.value;
    });
    return outputObject;
};

export default jQuery;