import $ from 'jquery';

export default class FileInput {
    constructor() {
        $('.js-filebox')
            .on('dragenter', (ev) => {
                const $this = $(ev.currentTarget);
                $this.addClass('input-file__box--before-drop');
            })
            .on('dragleave dragdrop', (ev) => {
                const $this = $(ev.currentTarget);
                $this.removeClass('input-file__box--before-drop');
            })
            .each((key, element) => {
                const $box = $(element);
                $box.find('.js-fileinput').on('change', (ev) => {
                    const $file = $(ev.currentTarget);
                    $file.trigger('keyup');
                    const $fileName = $box.find('.js-filename-preview');
                    const firstFileName = this.getFirstFileName($file.get(0));
                    if (firstFileName !== '') {
                        $fileName.html(firstFileName);
                    } else {
                        $fileName.html('Nie wybrano pliku');
                    }
                    $box.removeClass('input-file__box--before-drop');
                })
            })
        ;
    }

    getFirstFileName(input) {
        return (
            typeof input.files !== 'undefined'
            && typeof input.files[0] !== 'undefined'
            && typeof input.files[0].name !== 'undefined'
        ) ? input.files[0].name : '';
    }
}
