import $ from '@/jquery';
import * as utils from '@/utils';
import Step from '@/step';

export default class StepFive extends Step {
    constructor(stepNumber) {
        super(stepNumber);
        this.apiRoute = `/step-5`;

        // this.form.on('change', () => {
        //     this.refreshConditionalFields();
        // });
        // this.refreshConditionalFields();

        // this.form
        //     .find('[name="payment_pesel"]')
        //     .on('change blur', (ev) => {
        //         this.recalculateBirthDateIfEmpty(ev.currentTarget);
        //     });
    }

    // refreshConditionalFields() {
    //     // computing section
    //     const formValues = this.form.serializeObject();
    //     const payment_no_pesel = utils.get(formValues, 'payment_no_pesel', 0);

    //     // rendering section
    //     if (payment_no_pesel) {
    //         this.disableField('payment_pesel');
    //         this.enableField('payment_citzenship');
    //     } else {
    //         this.enableField('payment_pesel');
    //         this.disableField('payment_citzenship');
    //     }
    // }

    // disableField(name) {
    //     this.form.find(`[name="${name}"]`)
    //         .addClass('is-disabled')
    //         .attr('disabled', true)
    //     ;
    // }

    // enableField(name) {
    //     this.form.find(`[name="${name}"]`)
    //         .removeClass('is-disabled')
    //         .attr('disabled', false)
    //     ;
    // }

    // recalculateBirthDateIfEmpty(peselInput) {
    //     const birthDateInput = this.form.find('[name="payment_birthdate"]');
    //     const pesel = $(peselInput).val();
    //     if (birthDateInput.val()) {
    //         return;
    //     }
    //     if (!utils.isValidPESEL(pesel)) {
    //         return;
    //     }
    //     birthDateInput.val(
    //         utils.getDateFromPESEL(pesel)
    //     );
    //     birthDateInput.change();
    //     birthDateInput.keyup();
    // }
}