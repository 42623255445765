import jQuery from 'jquery';

/**
 * @param {object} obj
 * @param {(element: any, key: string|integer) => void} predicate
 */
export function forEach(obj, predicate) {
    for (let k in obj) {
        if (!obj.hasOwnProperty(k)) {
            continue;
        }
        predicate(obj[k], k);
    }
};

/**
 * @param {object} obj
 * @param {(element: any, key: string|integer) => void} predicate
 */
export function forEachBreakable(obj, predicate) {
    for (let k in obj) {
        if (!obj.hasOwnProperty(k)) {
            continue;
        }
        if (predicate(obj[k], k)) {
            return;
        }
    }
};

export function log() {
    if (process.env.NODE_ENV !== 'development'){
        return;
    }
    console.log.apply(console.log, arguments);
}

/**
 * @param {jQuery|string|HTMLElement} element - HTML element, jQuery element or s
 */
export function scrollTo(element, durationMs = 500, offset = 0) {
    let $el;
    if (element instanceof jQuery) {
        $el = element;
    } else {
        $el = jQuery(element);
    }
    if (!$el.length) {
        return;
    }
    jQuery('html, body').stop().animate({
        scrollTop: ($el.first().offset().top + offset)
    }, durationMs);
}

export function getResponseData(response) {
    if (response && typeof response.data !== 'undefined') {
        return response.data;
    }
    return response;
}

export function get(obj, key, fallback = null) {
    if (typeof obj[key] !== 'undefined') {
        return obj[key];
    }
    return fallback;
}

/**
 * @param {string} pesel
 */
// export function isValidPESEL(pesel) {
//     if (pesel.length < 11) {
//         return false;
//     }
//     // Now we verify using weights method
//     var weights = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
//     var sum = 0;

//     for (var i = 0; i < weights.length; i++) {
//         sum += (parseInt(pesel.substring(i, i + 1), 10) * weights[i]);
//     }
//     sum = sum % 10;
//     return (sum === parseInt(pesel.substring(10, 11), 10));
// }

/**
 * This function returns date string from valid PESEL. You have
 * to validate it first before usage
 *
 * @param {string} pesel
 * @returns {string} date string in ISO format YYYY-MM-DD
 */
// export function getDateFromPESEL(pesel) {
//     var year = parseInt(pesel.substring(0, 2), 10);
//     var month = parseInt(pesel.substring(2, 4), 10) - 1;
//     var day = parseInt(pesel.substring(4, 6), 10);

//     if (month > 80) {
//         year = year + 1800;
//         month = month - 80;
//     }
//     else if (month > 60) {
//         year = year + 2200;
//         month = month - 60;
//     }
//     else if (month > 40) {
//         year = year + 2100;
//         month = month - 40;
//     }
//     else if (month > 20) {
//         year = year + 2000;
//         month = month - 20;
//     }
//     else {
//         year += 1900;
//     }

//     var birthDate = new Date();
//     birthDate.setFullYear(year, month, day);
//     return birthDate.toISOString().split('T')[0];
// }