import jQuery from 'jquery-validation';
import ErrorsBag from '@/errorsBag';
import * as utils from '@/utils';
import mime from 'mime-types';

/**
 * Unicode ranges:
 * Latin supplement 1 = \u00c0-\u00f6\u00f8-\u00ff (http://www.unicode.org/charts/PDF/U0080.pdf)
 * Latin extended A = \u0100-\u017f (http://www.unicode.org/charts/PDF/U0100.pdf)
 * Latin extended B = \u0180-\u024f (http://www.unicode.org/charts/PDF/U0180.pdf)
 */

const MEGA_BYTE_BYTES = 1024 * 1024;

let tempMessage = '';
/**
 * @returns {string}
 */
function dynamicApiMessage() {
    return tempMessage;
};

/**
 * @param {jQuery} element
 */
function findClosestForm(element) {
    return element.closest('form');
}

/**
 * @param {jQuery} form
 * @param {string} form
 * @returns {string|boolean}
 */
function popErrorFromFromErrorsBag(form, fieldName) {
    const formElement = form.get(0);
    if (
        !formElement 
        || !(typeof formElement.errorsBag !== 'undefined')
        || !(formElement.errorsBag instanceof ErrorsBag)
    ) {
        return false;
    }
    return formElement.errorsBag.pop(fieldName);
}

/**
 * @param {HTMLInputElement} fileInput 
 * @param {(file: File, index: Number) => void} callback 
 */
function iterateThroughFiles(fileInput, callback) {
    if (fileInput.files.length > 0) { 
        for (let i = 0; i <= fileInput.files.length - 1; i++) { 
            callback(fileInput.files.item(i), i);
        }
    }
}

jQuery.validator.addMethod(
    'api',
    /**
     * @param {string} value 
     * @param {HTMLElement} element 
     */
    function(value, element) {
        const name = element.name
            ,$element = jQuery(element)
            ,$form = findClosestForm($element)
            ,error = popErrorFromFromErrorsBag($form, name)
        ;
        
        utils.log(
            'jquery.validate.js: "api" method called'
            ,name
            ,$element
            ,$form
            ,error
        );

        if (error) {
            tempMessage = error;
            return false;
        }
        
        return true;
    }, 
    dynamicApiMessage
);

jQuery.validator.addMethod(
    'phone', 
    /**
     * @param {string} value
     */
    function(value) {
        if (typeof value !== 'string') {
            return false;
        }
        return value.match(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);
    }, 
    'Niepoprawny numer telefonu'
);

jQuery.validator.addMethod(
    'title', 
    /**
     * @param {string} value
     */
    function(value) {
        if (typeof value !== 'string') {
            return false;
        }
        return value.match(/^[a-zA-Z\u00c0-\u00f6\u00f8-\u017f0-9_\s-.,]+$/);
    }, 
    'Niepoprawny tytuł'
);

jQuery.validator.addMethod(
    'address', 
    /**
     * @param {string} value
     */
    function(value) {
        if (typeof value !== 'string') {
            return false;
        }
        return value.match(/^[a-zA-Z\u00c0-\u00f6\u00f8-\u017f0-9_\s-.,\/]+$/);
    }, 
    'Niepoprawny adres'
);

jQuery.validator.addMethod(
    'postcode', 
    /**
     * @param {string} value
     */
    function(value) {
        if (typeof value !== 'string') {
            return false;
        }
        return value.match(/^(\d){2}[ \-]{0,1}(\d){3}$/);
    }, 
    'Niepoprawny kod pocztowy'
);

jQuery.validator.addMethod(
    'file-maxsize', 
    /**
     * @param {string} value
     * @param {HTMLElement} element
     * @param {number|string} maxSize - max size in MB
     */
    function(value, element, maxSize) {
        let summarySize = 0;

        iterateThroughFiles(element, (file) => {
            summarySize += file.size / MEGA_BYTE_BYTES;
        });
        
        utils.log(value, element, maxSize, summarySize);
        
        return summarySize <= parseFloat(maxSize);
    }, 
    'Plik jest za duży'
);

jQuery.validator.addMethod(
    'file-type',
    /**
     * @param {string} value
     * @param {HTMLElement} element
     * @param {string} types - pipe separated file extensions eg. jpeg|jpg|png
     */
    function(value, element, types) {
        if (!types) {
            return true;
        }

        const typesArray = types.split('|');
        let allowed = true;

        iterateThroughFiles(element, (file) => {
            const ext = mime.extension(file.type);
            allowed &= typesArray.indexOf(ext) !== -1;
        });
        
        return allowed;
    }, 
    'Plik ma niedozwolony typ'
);

jQuery.validator.addMethod(
    'customdate', 
    /**
     * @param {string} value
     * @param {HTMLElement} element
     * @param {string} pattern - must consist of YYYY, MM and DD elements
     */
    function(value, element, pattern) {
        let parsedPattern = pattern.replace('-', '\\-');
        
        parsedPattern = parsedPattern.replace('YYYY', '[1-9][0-9][0-9][0-9]');
        parsedPattern = parsedPattern.replace('MM', '(0[1-9]|1[0-2])');
        parsedPattern = parsedPattern.replace('DD', '(0[1-9]|[1-2][0-9]|3[0-1])');

        const regexp = new RegExp(`^${parsedPattern}\$`, 'g');
    
        return regexp.test(value);
    }, 
    jQuery.validator.format('Wprowadź datę w formacie {0}')
);


/**
 * By default this rule is optional unless we pass
 * parameter "0" (or indeed other than "1") through
 * data-rule-pesel html attribute. If we just leave
 * empty attribute, like:
 *   <input data-rule-pesel type="text"/> 
 * then it will be trated as not mandatory field
 */
jQuery.validator.addMethod(
    'pesel',
    /**
     * @param {string} value
     * @param {HTMLElement} element
     * @param {string} optional
     */
    function(value, element, optional) {
        const opt = optional === true || optional === "1";
        console.log('PESEL PARAM', optional, opt, !value);
        if (opt && !value) {
            return true;
        }
        return utils.isValidPESEL(value);
    },
    'PESEL jest nieprawidłowy'
);
