import * as utils from '@/utils';

let counter = 0;
const staticReponses = {
    'step-1': [
        {},
        {
            errors: {
                _general_errors: 'Jakiś błąd po stronie serwera'
            }
        },
        {
            errors: {
                code: 'Ten kod został już wcześniej użyty. W przypadku wątpliwości, należy <a href="/kontakt.html">skontaktować się z nami.</a>'
            }
        },
        {},
    ],
    'step-2': [
        {},
        {
            errors: {
                _general_errors: 'Jakiś błąd po stronie serwera'
            }
        },
        {
            errors: {
                name: 'Błąd',
                surname: 'Błąd 2'
            }
        },
    ],
    'step-3': [
        {},
        {
            errors: {
                _general_errors: 'Jakiś błąd po stronie serwera'
            }
        },
    ],
    'step-4': [
        {},
        {
            errors: {
                _general_errors: 'Jakiś błąd po stronie serwera'
            }
        },
    ],
    'step-5': [
        {},
        {
            errors: {
                _general_errors: 'Jakiś błąd po stronie serwera'
            }
        },
    ],
};

/**
 * @param {string} urlToMatch
 */
function drawSomeResponse(urlToMatch) {
    let resp = {};
    counter++;
    utils.forEachBreakable(staticReponses, (item, endpointKey) => {
        if (urlToMatch.match(endpointKey)) {
            utils.log('axiosMock: draw response endpoint:', urlToMatch);
            const len = item.length;
            if (!len) {
                return true;
            }
            const key = counter % len;
            resp = item[key];
            return true;
        }
    });
    return resp;
}

/**
 * @param {object} payload
 * @param {object} payload.url
 */
export default function apiLocalMock(payload) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(drawSomeResponse(payload.url));
        }, Math.random() * 1000);

    });
}