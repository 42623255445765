import $ from 'jquery';
import Wizard from './wizard';
import FileInput from './fileInput'

$('.checkbox-area').click(function(){
    let $checkbox = $(this).find(':checkbox');
    $checkbox.attr('checked', !$checkbox.attr('checked'));
    $(this).toggleClass('checked');
});

$(document).ready(() => {
    new FileInput();
    new Wizard();

    //overlay helpers
    $('.show-legal-lottery').click(function(){
        $('#legal-lottery').show();
    });

    $('.show-legal-marketing').click(function(){
        $('#legal-marketing').show();
    });

    $('.button-close').click(function(){
        $('.overlay').hide();
    }) 
});
