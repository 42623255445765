import $ from 'jquery-validation';
import * as utils from '@/utils';
import Step from '@/step';
import StepOne from '@/steps/stepOne';
import StepTwo from '@/steps/stepTwo';
import StepThree from '@/steps/stepThree';
import StepFour from '@/steps/stepFour';
import StepFive from '@/steps/stepFive';
import StepThankYou from '@/steps/stepThankYou';

export default class Wizard {
    constructor() {
        this.steps = {
            1: new StepOne(1), // inherit from Step class to implement specific step processing (stepRequest)
            2: new StepTwo(2),
            3: new StepThree(3),
            4: new StepFour(4),
            5: new StepFive(5),
            6: new StepThankYou(6),
        };

        this.currentStep = 1;

        this.goToStep(this.currentStep);

        $('[data-next-step]').on('click', () => {
            this.nextStep();
        });

        $('[data-prev-step]').on('click', () => {
            this.prevStep();
        });
    }

    nextStep() {
        const step = this.getStep(this.currentStep);
        step.validate();
        if (!step || !step.isValid()) {
            utils.log('wizard.js: Step not found or invalid');
            step.scrollToFirstError();
            return false;
        }
        step.submitStep()
            .then((response) => {
                utils.log('wizard.js: Step submitted');
                step.validate(true);
                if (!step.isValid() || !(typeof this.steps[this.currentStep+1] !== 'undefined')) {
                    utils.log('wizard.js: Step invalid');
                    step.scrollToFirstError();
                    return false;
                }
                if (!response.isOk) {
                    utils.log('wizard.js: Response is not positive');
                    // Some errors occured but were not related with any of fields
                    // which belongs to current form
                    return false;
                }
                this.currentStep++;
                this.goToStep(this.currentStep);
            })
            .catch((e) => {
                utils.log('wizard.js: Request failed', e);
            });
        ;
    }

    prevStep() {
        if (this.currentStep >= 2) {
            this.currentStep--;
            this.goToStep(this.currentStep);
        }
    }

    goToStep(stepNumber) {
        const all = $('[data-step]');
        all.removeClass('form-steps--active');
        all.filter((k, el) => {
            return $(el).attr('data-step') == stepNumber;
        }).addClass('form-steps--active');
        utils.scrollTo('#page-top');
    }

    stepIsAccessible(stepNumber) {
        const step = this.getStep(stepNumber);
        return step ? step.isAccessible() : false;
    }
    /**
     * @param {number|string} stepNumber
     * @returns {boolean|Step}
     */
    getStep(stepNumber) {
        return typeof this.steps[stepNumber] !== 'undefined'
            ? this.steps[stepNumber]
            : false;
    }
}
