import axios from 'axios';
import axiosMock from '@/axiosMock'

// this module is for middleware injection purposes
// or for mocking-up purposes

let axiosExport = axios;

if (process.env.NODE_ENV !== 'development') {
    axiosExport = axiosMock;
}

export default axiosExport;