import $ from '@/jquery';
import * as utils from '@/utils';
import ErrorsBag from '@/errorsBag';
import axios from '@/axios';
import { apiUrl } from '@/config';

export default class Step {
    constructor(stepNumber) {
        this.valid = true;
        this.stepNumber = stepNumber;
        this.scope = $(`[data-step="${stepNumber}"]`);

        if (!this.scope.length) {
            return;
        }

        this.form = $(`#form-step-${stepNumber}`);

        this.buttons = {
            nextStep: this.scope.find('[data-next-step]'),
            prevStep: this.scope.find('[data-prev-step]'),
        };

        this.formElement = this.form.get(0);
        this.formElement.errorsBag = new ErrorsBag();
        this.validator = false;
        this.apiRoute = `/step-example`;
    }

    serialize() {
        return this.form.serializeArray();
    }

    validate(reset = false) {
        if (reset && this.validator) {
            this.validator.destroy();
            this.validator = false;
        }
        this.validator = this.form.validate();
        this.valid = this.form.valid();
    }

    /**
     * @returns {Promise}
     */
    stepRequest() {
        const formData = new FormData(this.formElement);

        utils.log(formData);

        return axios({
            method: 'post',
            url: `${apiUrl}${this.apiRoute}`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
    }

    /**
     * @returns {Promise}
     */
    submitStep() {
        this.startLoader();
        return new Promise((resolve, reject) => {
            this.stepRequest()
                .then((response) => {
                    this.stopLoader();
                    const data = utils.getResponseData(response);
                    utils.log('step.js: submitStep then', response, data);
                    if (
                        typeof data.errors === 'object'
                        && Object.keys(data.errors).length
                    ) {
                        this.pushErrors(data.errors);
                        resolve({isOk: false, response});
                    } else {
                        resolve({isOk: true, response});
                    }
                })
                .catch((e) => {
                    this.stopLoader();
                    const data = utils.getResponseData(e);
                    utils.log('step.js: submitStep catch:', e, data);
                    if (
                        typeof data.errors === 'object'
                        && Object.keys(data.errors).length
                    ) {
                        this.pushErrors(data.errors);
                        resolve({isOk: false, response: e});
                    } else {
                        reject({isOk: false, response: e});
                    }
                })
            ;
        });
    }

    startLoader() {
        this.scope.addClass('form-steps__form--loading');
        this.buttons.nextStep.addClass('is-waiting');
        this.buttons.prevStep.addClass('is-waiting');
    }

    stopLoader() {
        this.scope.removeClass('form-steps__form--loading');
        this.buttons.nextStep.removeClass('is-waiting');
        this.buttons.prevStep.removeClass('is-waiting');
    }

    /**
     *
     * @param {{[key: string]: string|string[]}} errors
     */
    pushErrors(errors) {
        utils.forEach(errors, (error, fieldName) => {
            if (error instanceof Array) {
                this.addErrorToAPIErrorsBag(
                    fieldName,
                    error.reduce((prev, currentError) => {
                        return `${prev}<br/>${currentError}`;
                    })
                );
            } else if (typeof error === 'string') {
                this.addErrorToAPIErrorsBag(fieldName, error);
            }
        });
    }

    addErrorToAPIErrorsBag(fieldName, error) {
        this.formElement.errorsBag.set(fieldName, error);
    }

    /**
     * @returns {boolean}
     */
    isValid() {
        return this.valid;
    }

    scrollToFirstError() {
        if (
            !this.validator
            || !this.validator.errorList
            || !this.validator.errorList[0]
            || !this.validator.errorList[0].element
        ) {
            return;
        }
        utils.scrollTo(this.validator.errorList[0].element, 500, -50);
    }
}
